// TODO v3, uses Webback / require to include common classes
class CommonApp {

}

// Main container
class Uoef extends CommonApp {

  constructor() {
    super();
    // Debug script.
    window.log = function (m) {
      console.log(m);
    };
    $(() => {
      this.init();
    });
  }

  init() {
    this.$loadingPageSpin = this.fetch$('loadingPageSpin');
    this.$loadingBlockSpin = $('.loadingBlockSpin');
    this.serviceCache = {};

    $.magnify && $('.imageFullscreen').magnify({
      icons: {
        close: 'fa fa-times',
        fullscreen: 'fa fa-expand',
        rotate: 'sync'
      }
    });
  }

  fetch(id) {
    return document.getElementById(id);
  }

  fetch$(id) {
    return $(this.fetch(id));
  }

  pageLoadingStart() {
    this.$loadingPageSpin
      .removeClass('fadeOut')
      .addClass('fadeIn');
    this.$loadingPageSpin.show();
  }

  pageLoadingStop() {
    this.$loadingPageSpin
      .removeClass('fadeIn')
      .addClass('fadeOut');

    this.$loadingPageSpin.one('animationend', () => {
      this.$loadingPageSpin.hide();
    });
  }

  blockLoadingStart(selector) {
    $(selector)
      .each((index, item) => {
        let $item = $(item);
        let $spin = this.$loadingBlockSpin.clone();
        $item.before($spin);
        $spin
          .width($item.width())
          .height($item.height());
        $spin.show();
      })
      .hide();
  }

  blockLoadingStop(selector) {
    $(selector)
      .show().each((index, item) => {
      let $spin = $(item).prev('.loadingBlockSpin');
      $spin.remove();
    });
  }

  blockLoadingToggle(selector, boolean) {
    // blockLoadingStart / blockLoadingStop.
    this['blockLoading' + (boolean ? 'Stop' : 'Start')](selector);
  }

  storeInit() {
    // Only once.
    if (!this.store) {
      // Local storage manager.
      this.store = {
        set: function (elem, value) {
          if (typeof (Storage) !== "undefined") {
            localStorage.setItem(elem, value);
          } else {
            document.cookie = elem + "=" + value;
          }
        }, get: function (elem) {
          if (typeof (Storage) !== "undefined") {
            return localStorage.getItem(elem);
          } else {
            var name = elem + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0) == ' ')
                c = c.substring(1);
              if (c.indexOf(name) == 0)
                return c.substring(name.length, c.length);
            }
            return "";
          }
        }
      }
    }
    return this.store;
  }

  /**
   * Used by template to register data to javascript.
   * @param name
   * @param jsonValue
   */
  setParameters(name, jsonValue) {
    this[name] = JSON.parse(jsonValue);
  }

  service(path, complete, options) {
    options = options || {};
    // Is a element of the page.
    if (typeof options.loading === 'string') {
      this.blockLoadingStart(options.loading);
    }
    // Is true.
    else if (options.loading) {
      this.pageLoadingStart();
    }

    $.ajax({
      url: basepath + path,
      method: options.method || 'GET',
      dataType: 'json',
      data: options && options.data,
      complete: (response) => {
        if (typeof options.loading === 'string') {
          this.blockLoadingStop(options.loading);
        }
        // Is true.
        else if (options.loading) {
          this.pageLoadingStop();
        }
        complete && complete(response)
      }
    });
  }

  serviceCached(path, complete, options) {
    if (this.serviceCache[path]) {
      // Reuse cached version.
      complete(this.serviceCache[path])
    }
    else {
      this.service(path, (response) => {
        // Save in cache.
        this.serviceCache[path] = response;
        complete && complete(response);
      }, options);
    }
  }

  numberWithSpaces(x) {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0;
  }

  priceWithTax(price) {
    return Math.round((price + (price / 100 * this.tva)) * 100) / 100
  }

  snakeToCamel(s) {
    return s.replace(/(\_\w)/g, function (m) {
      return m[1].toUpperCase();
    });
  }

  classify(string) {
    let output = this.snakeToCamel(string);
    return output[0].toUpperCase() + output.slice(1)
  }

  timestamp() {
    return window.performance &&
    window.performance.now &&
    window.performance.timing &&
    window.performance.timing.navigationStart ?
      window.performance.now() +
      window.performance.timing.navigationStart : Date.now();
  }
}

class UoefContext {
  constructor() {
    // Only one context
    uoef.context = this;

    $(() => {
      this.init();
    });
  }

  init() {
    this.tables = [];

    // Lists.
    $('.abstract-list-table').each((index, dom) => {
      let className = uoef.classify(dom.getAttribute('data-abstract-list-type')) + 'List';
      if (!window[className]) {
        className = 'AbstractList';
      }
      this.tables.push(new window[className]({$: $(dom)}));
    });
  }

  loadingStart() {
    uoef.pageLoadingStart();
  }

  loadingStop() {
    uoef.pageLoadingStop();
  }

  /**
   * Fill Samples Form in client side with fake info.
   */
  debugFormSampleFill() {
    let title = 'Test sample';
    $('#echantillon_raison_sociale_diplome,' +
      '#echantillon_designation_commerciale').val(title);
    $('#echantillon_cuvee').val(1990);
    // Red
    $('#echantillon_id_couleur').val(1);
    // Quiet
    $('#echantillon_id_type_vin').val(4);
    $('#echantillon_sucres').val(12);
    $('#echantillon_alcool').val(34);
    $('#echantillon_stock').val(99);
    // 50 cl
    $('#echantillon_id_flask').val(11);
    $('#echantillon_num_lot').val('TEST');
    $('#echantillon_prix_vente').val(56);
    // Woody
    $('[name="echantillon[parametres][0][valeur]"]:first').attr('checked', 'checked');
    // Organic
    $('[name="echantillon[parametres][1][valeur]"]:last').attr('checked', 'checked');
  }

  debugFormUserFillStep1() {
    let time = (new Date()).getTime();
    let letters = 'abcdefghij';
    let code = '';

    // Create a code with only letters for username.
    for (let item of time.toString().split('')) {
      code += letters[parseInt(item)];
    }

    $('#producteur_raison_sociale,' +
      '#producteur_contact').val('Test user');
    $('#producteur_adresse1').val('Adresse du producteur 1');
    $('#producteur_adresse2').val('Adresse du producteur 2');
    $('#producteur_adresse3').val('Adresse du producteur 3');
    $('#producteur_code_postal').val('CP1234');
    $('#producteur_ville').val('Villeprod sur mer');
    // 24 = France
    $('#producteur_id_pays').val(24);
    $('#producteur_tel').val('06 65 54 34 32');
    $('#producteur_fax').val('06 65 54 34 33');
    $('#producteur_email_oenologue').val('oenologues@producteur.test');
    $('#producteur_siret').val('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
    $('#producteur_id_activite').val(9);
    $('#producteur_utilisateur_username').val('testuser' + code);
    // Mail
    $('#producteur_email_first').val(code + '@test.test');
    $('#producteur_email_second').val(code + '@test.test');
    // Pass
    $('#producteur_utilisateur_password_first').val(code + '1');
    $('#producteur_utilisateur_password_second').val(code + '1');
  }
}

// This should be the new abstractList
class UoefTable {
  constructor(options) {
    $.extend(true, this, {
      language: {
        // First usage is to merge calls to this file.
        url: '/assets/global/plugins/datatables/plugins/i18n/French.json'
      }
    }, options);
  }
}

class UoefForm {
  constructor(options) {
    // Merge options.
    $.extend(true, this, {
      // Need a form name for fields prefixes.
      name: ''
    }, options);
    // Define help variables.
    this.variables(options);
    // Run when ready.
    $(() => {
      // Binding does not work.
      this.init();
    });
  }

  variables() {
    let regExp = /\[([^)]+)\]/;
    // Find all inputs of the form.
    $('[name^=' + this.name + '\\[]').each((index, field) => {
      // Extract name.
      let fieldName = regExp.exec($(field).attr('name'))[1];
      // field_name to this.$fieldName.
      let varName = '$' + uoef.snakeToCamel(fieldName);
      // Store it.
      this[varName] = this.fetchField$(fieldName);
    });
  }

  init() {
    this.$ = $('form[name="' + this.name + '"]');
    // To override...
  }

  fetchField(fieldName) {
    return uoef.fetch(this.name + '_' + fieldName);
  }

  fetchField$(fieldName) {
    return $(this.fetchField(fieldName));
  }

  fetchFieldLine$(fieldName) {
    return this.$.find('.fieldLine-' + fieldName);
  }
}

window.uoef = new Uoef();

